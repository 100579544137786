import React from "react";

// Customizable Area Start
import { theme } from "../../dashboard/src/CustomizedElementStyle";
import { ThemeProvider } from "@material-ui/core/styles";
import { Box, Container, Grid, Button, Divider } from "@material-ui/core";

// Customizable Area End
//Customizable Area Start
import FeaturesLandingpageController, {Props} from "../../email-account-registration/src/FeaturesLandingpageController.web";
import DOMPurify from "dompurify";
//Customizable Area End 

class Features extends FeaturesLandingpageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <ThemeProvider theme={theme}>
          <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {this.props.background()}
            <Container style={{ position: "relative", textAlign: "start", maxWidth: "96vw" }}>
            {this.props.featuresContent &&
              <Grid container style={{ marginTop: 160 }}>
              <Grid item lg={12} sm={12} xs={12}>
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.featuresContent.content)}} data-test-id="featuresContent">
              </div>
              </Grid>
              </Grid>
            }
            <Button data-test-id="partner" style={{...webStyle.becomePartner}} 
              onClick={() => {
                this.props.handleBecomeAPartner('register')
              }}>
              {"Become a partner/seller"}
            </Button>
            </Container>
          </Box>
          <Divider />
          {this.props.footer()}
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default Features;
const webStyle = {
  res: {},
  credits: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontWeight: 500,
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.7)"
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    width: "100%",
    overflowX: "hidden"
  },
  navigation: {
    paddingRight: "50px",
    border: "none",
    height: "50px",
    width: "fit-content",
    alignItems: "center",
    display: "flex",
    fontWeight: 400
  },
  signUpForFree: {
    width: "fit-content",
    // padding: "15px 30px",
    fontSize: 16,
    fontWeight: 700,
    color: "white",
    borderRadius: "7px",
    fontFamily: `"Lato", sans-serif`
  },
  text: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: 16,
    color: "#A8A8A8"
  },
  textStyle: {
    color: 'rgb(87,86,86)',
    fontFamily: "Lato, sans-serif",
    fontSize: '14px',
    cursor: 'pointer'
  },
  textOne: {
    fontFamily: "Lato, sans-serif",
    fontSize: '14px',
    color: 'rgb(87,86,86)',
    cursor: 'pointer'
  },
  container: { 
    fontFamily: `"Lato",  sans-serif`, 
    fontSize: 24, 
    fontWeight: 600, 
    color: "#F3732B",
    "@media (max-width: 768px)": {
      "margin-top": "100px",
    }
  },
  becomePartner: {
    margin:'10px 0 50px',
    padding: "10px 22px",
    borderRadius: "6px",
    background: "rgba(253, 97, 12, 1)",
    fontSize: 16,
    fontWeight: 600,
    color: "white",
    fontFamily: `"Lato",  sans-serif`,
    height: "max-content",
    width: "max-content", 
    textTransform: "none" as const,
    cursor: "pointer" 
  }
};
// Customizable Area End
