import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import { IStaticError, IStaticPageData } from "./Interface";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  selectedPage: string;
  content: string[];
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class StaticPageController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetStaticPagesCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.ReceiveMessage), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      selectedPage: "",
      content: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiGetStaticPagesCallId) {
        this.handleSubmitContactResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleSubmitContactResponse = (responseJson: IStaticError | IStaticPageData[]) => {
    if ("errors" in responseJson) {
      return;
    }
    if (responseJson.length > 0) {
      const staticPages = configJSON.staticPages;
      const path = window.location.pathname.split("/")[1];
      if (staticPages.includes(path)) {
        let pageValue: string;
        switch (path) {
          case "about-us":
            pageValue = "About us";
            break;
          case "testimonials":
            pageValue = "Testimonials";
            break;
          case "contact":
            pageValue = "Contact";
            break;
          case "privacy-policy":
            pageValue = "Privacy policy";
            break;
          case "support":
            pageValue = "Support";
            break;
          case "terms-conditions":
            pageValue = "Terms & Conditions";
            break;
          case "shipping-returns":
            pageValue = "Shipping & Returns";
            break;
        }
        this.setState({
          content: responseJson
            .filter((item) => item.title === pageValue)[0]
            .content.split(/\r?\n+/)
            .filter((item) => item !== ""),
        });
      }
    }
  };

  getStaticPages = async () => {
    const headers = { "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetStaticPagesCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiStaticPagesEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount(): Promise<void> {
    this.getStaticPages();
  }
  // Customizable Area End
}
