import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getNavigationMessage } from "../../../components/src/CommonFunctions";
import { createRef } from "react";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { Message } from "framework/src/Message";
import { IPrivacyNotice } from "../../termsconditions/src/PrivacyNoticeController.web";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  footer?: any;
  background?: any;
  classes?: {
    navContent: string;
    signUpButton: string;
  },
  whyUsContent?: IPrivacyNotice,
  pricingContent?: IPrivacyNotice
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  content: string[];
  currentTab: string;
  featuresContent: IPrivacyNotice,
  whyUsContent : IPrivacyNotice,
  pricingContent: IPrivacyNotice,
  footerTitle: string,
  isFooterLinkClicked: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class FeaturesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiStaticContentCallId: string = "";
  registerRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.ReceiveMessage), getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      content: [],
      currentTab: "register",
      featuresContent: {
        id: '',
        title: '',
        content: ''
      },
      whyUsContent: {
        id: '',
        title: '',
        content: ''
      },
      pricingContent: {
        id: '',
        title: '',
        content: ''
      },
      footerTitle: '',
      isFooterLinkClicked: false
    };
    this.registerRef = createRef()
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  handleFeatures = (tabValue: string) => {
    this.setState({ currentTab: tabValue });
  };
  handleWhyus = (tabValue: string) => {
    this.setState({ currentTab: tabValue });
  };
  handlePricing = (tabValue: string) => {
    this.setState({ currentTab: tabValue });
  };
  handleRegistration = (tabValue: string) => {
    this.setState({ currentTab: tabValue });
  };
  handleLogo = () => {
  this.props.navigation.navigate("FeaturesHeader");
  };

  handlePrivacy = () => {
    this.send(getNavigationMessage('PrivacyNotice', this.props));
  }
  
  handleTerms = () => {
    this.send(getNavigationMessage('TermsOfService', this.props));
  }

  handleBecomeAPartner = (tabValue: string) => {
    this.registerRef.current?.scrollIntoView({ behavior: 'smooth', block: "start" });
    this.setState({currentTab: tabValue})
  }

  async componentDidMount() {
    this.getStaticData()
  }
  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) ;
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId === this.apiStaticContentCallId) {
        this.handleStaticContentResponse(responseJson);
      }
    }
  }

  handleStaticContentResponse = (responseJson:IPrivacyNotice[]) => {
    if(responseJson.length) {
      this.getFeaturesContent(responseJson)
      this.getWhyusContent(responseJson)
      this.getPricingContent(responseJson)  
    }
  }

  getPricingContent = (responseJson: IPrivacyNotice[]) => {
    const pricingContent = responseJson.filter((item:IPrivacyNotice) => {
      if(item.title === "Pricing") {
          return item
      }
    })
    if(pricingContent.length) {
      this.setState({pricingContent : pricingContent[0]})  
    }
  }

  getWhyusContent = (responseJson: IPrivacyNotice[]) => {
    const whyUsContent = responseJson.filter((item:IPrivacyNotice) => {
      if(item.title === "Why us?") {
          return item
      }
    })
    if(whyUsContent.length) {
      this.setState({whyUsContent : whyUsContent[0]})
    }
  }

  getFeaturesContent = (responseJson: IPrivacyNotice[]) => {
    const featuresContent = responseJson.filter((item:IPrivacyNotice) => {
      if(item.title === "Features") {
          return item
      }
    })
      if(featuresContent.length) {
        this.setState({featuresContent : featuresContent[0]})
      }
  }

  getStaticData = async() => {
    const headers = { token: await getStorageData("authToken"), "Content-Type": configJSON.contentType };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiStaticContentCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.staticApiEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setFooterTitle = (type: string) => {
    setStorageData('footerTitle',type)
    this.props.navigation.navigate("SellWithByEzzy", {type: type.toLocaleLowerCase()})
  }
  // Customizable Area End
}
