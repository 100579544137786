import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { InputProps } from "@material-ui/core/Input";
import { createRef } from "react";
import { AttributesData, OptionObjData } from "../SellerHomeDashboardComponents/Interfaces";
import { getStorageData } from "../../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { SetCookies } from "../../../../framework/src/WebUtilities";
import * as Yup from "yup";

export const initialValues = {
  productTitle: "",
  productPrice: ""
};

export interface IAttributesList {
    id: string,
    attribute_name: string,
    checked?: boolean,
    attribute_options_attributes?: {
        id: number,
        option: string
      
    }[]
}

interface IResponseData {
  data: [],
  errors: {[key:string]: string}[]
}

interface IErrorResponse {
  errors: string[]
}

interface ISelectGroupData {
  id: string,
  attribute_name: string,
  option: string,
  attribute_options: {
    id: string,
    option: string
  }[]
}

export interface IExistingGroupList {
  id: string;
  type: string;
  attributes: {
    id: number;
    seller_id: number;
    micro_category_id: number;
    group_name: string;
    variant_attributes: {
          id: number;
          attribute_name: string;
          attribute_options: {
              id: number;
              option: string;
            }[]
        }[],
      micro_category: {
        data: {
          id: string;
          type: string;
          attributes :{
            id: number;
            name: string;
            mini_category_id: number;
            sub_category_id: number;
            category_id: number;
            relation: string;
          }
        }
      }
    }
}

export interface IExistingGroupListRes {
  data: IExistingGroupList[]
} 

export interface IExistingVariantList {
  id: string,
    type: string,
    attributes: {
      id: number,
      catalogue_variant_id: number,
      catalogue_id: number,
      product_sku: string,
      product_besku: string,
      product_description: null,
      price: null,
      product_title: null,
      product_images: null,
      group_attributes: {
        attribute_name: string
        options: string[]
        }[],
      individual_group_attr_with_ids: {
        id: number
        attribute_name: string
        options: string
        variant_attribute_id: number
        attribute_option_id: number
        }[],
      catalogue: {
        data: {
          id: string
          type: string
          attributes: object
            category_id: number
            brand_id: number
            parent_catalogue_id: null
            warehouse_id: null
            seller_id: number
            sku: string
            besku: string
            product_title: string
            fulfilled_type: string
            product_type: string
            stocks: null
            content_status: string
            status: boolean
            product_image: string,
            parent_catalogue: null,
            category: {
              id: number,
              name: string
            },
            sub_category: {
              id: number,
              name: string
            },
            mini_category: {
              id: number,
              name: string
            },
            micro_category: {
              id: number,
              name: string
            },
            brand: {
              id: number,
              brand_name: string
            },
            product_rating: null,
            product_content: {
              product_attributes: {
                id: number
                gtin: string
                unique_psku: string
                brand_name: string
                product_title: string
                mrp: null
                retail_price: string
                long_description: string
                whats_in_the_package: string
                country_of_origin: string
                catalogue_id: number,
              },
              image_urls: [],
              feature_bullets: [],
              custom_fields_contents: []
            },
            product_variant_groups: {
                id: number
                product_sku: string
                product_besku: string
                product_description: null
                price: null
                product_title: null
                product_images: null
                group_attributes: {
                    attribute_name: string,
                    options: string[]
                  }[]
              }[]
          }
        }
      },
      catalogue_variant: {
        data: IExistingGroupList
      }
}

interface IExistingVariantListRes {
  data: IExistingVariantList[]
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    delivered: string;
    backButton: string;
    submitButton: string;
    addAttributeFormikWrraper: string;
    addVariantFormikWrraper: string;
    mainContainer: string;
    veriantContainer: any;
    selectGroupWrraper: string;
    confirmCancelButton: string;
    errorStyle: string;
    sku: string;
    validateBtn: string;
    nextError: string;
    validateWrap: string;
    deleteWrap: string;
    check: string;
    cancel: string;
    checkWrap: string;
    cancelWrap: string;
    tableContainer: string;
    text: string;
    tableHeaderText: string;
    tableBorder: string;
    tableRowClass: string;
    radioButton: string;
    tableHeaderTextOne: string;
    labelHeader: string;
    listContainer: string;
    addOption: string;
    saveAttributes: string;
    attributeName: string;
  };
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  activeStep: string;
  addAttribute: boolean;
  AddToExistingGroup: boolean;
  attributeName: string;
  errormsg: string[];
  token: string;
  attributesList: IAttributesList[];
  allAttributeValue: boolean;
  attributeValues: AttributesData[];
  selectGroupData: ISelectGroupData[];
  selectGroupId: string;
  product_sku: string;
  validateError: string;
  skuValue: string[];
  isValidate: boolean[];
  errorMsg: string;
  isSkuValid: boolean[];
  isValidating: boolean;
  skuValidateIndex: number;
  openDeleteModal: boolean;
  openVariantDeleteModal: boolean;
  existingGroupList: IExistingGroupList[];
  existingVariantList: IExistingVariantList[];
  initialAttributesFormList: OptionObjData[];
  selectedExistingGroupData: IExistingGroupList | null;
  selectedExistingVariantData: IExistingVariantList | null;
  selectedForDeleteId: string;
  selectedVariantDeleteId: string;
  microCategoryId: string,
  createGroupErr: string,
  variantValidateErr: string[],
  skuValidateErr: string[],
  validateErrMsg: string[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class VariantController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  inputRef: React.RefObject<InputProps & { click: Function }>;
  apiPostCreateGroupId: string = "";
  apiPostCreateVariantId: string = "";
  getGroupDetailsId: string = "";
  apiValidateSKUCallId: string = "";
  getExistingGroupListId: string = "";
  getExistingVariantListId: string = "";
  deleteGroupId: string = "";
  deleteVariantId: string = "";
  getMicroCatInfoApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      activeStep: "variantHome",
      addAttribute: false,
      errormsg: [],
      AddToExistingGroup: false,
      attributeName: "",
      token: "",
      attributesList: [],
      validateError: "",
      skuValue: [],
      isValidate: [],
      selectGroupData: [],
      selectGroupId: "",
      product_sku: "",
      isSkuValid: [],
      isValidating: false,
      skuValidateIndex: -1,
      openDeleteModal: false,
      openVariantDeleteModal: false,
      errorMsg: "",
      allAttributeValue: true,
      loading: false,
      attributeValues: [],
      existingGroupList: [],
      existingVariantList: [],
      initialAttributesFormList: [{ attribute_name: "", attribute_options_attributes: [""] }],
      selectedExistingGroupData: null,
      selectedExistingVariantData: null,
      selectedForDeleteId: "",
      selectedVariantDeleteId: "",
      microCategoryId: '',
      createGroupErr: '',
      variantValidateErr: [],
      skuValidateErr: [],
      validateErrMsg: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.inputRef = createRef();
  }
  // Customizable Area End

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.handleSuccessResponse(responseJson, apiRequestCallId, errorResponse);
    }

    // Customizable Area End
  }
  async componentDidMount(): Promise<void> {
    const catalogId = await getStorageData("catalog_id");
    await this.getToken();
    this.getMicroCategoryId(catalogId);
  }

  getMicroCategoryId = async(catalogId:string) => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: await getStorageData("authToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getMicroCatInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/${catalogId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSuccessResponse = (responseJson: any, apiRequestCallId: string, errorResponse: IErrorResponse) => {
    switch (apiRequestCallId) {
      case this.apiPostCreateGroupId:
        this.handleCreateGroupResponse(responseJson, errorResponse);
        break;

      case this.apiPostCreateVariantId:
        this.handleCreateVariantResponse(responseJson, errorResponse);
        break;

      case this.getGroupDetailsId:
        this.handleGroupDetailsApiCallResponse(responseJson, errorResponse);
        break;
      case this.apiValidateSKUCallId:
        this.handleValidateChildSKU(responseJson);
        break;
      case this.getExistingGroupListId:
        this.handleExistinGroupListApiResponse(responseJson);
        break;
      case this.getExistingVariantListId:
        this.handleExistinVariantListApiResponse(responseJson);
        break;
      case this.deleteGroupId:
        this.handleDeleteGroupResponse(responseJson, errorResponse);
        break;
      case this.deleteVariantId:
        this.handleDeleteVariantResponse(responseJson, errorResponse);
        break;
      case this.getMicroCatInfoApiCallId: 
        this.handleMicroCatId(responseJson) 
    }
  };

  handleMicroCatId = (responseJson:any) => {
    if(responseJson) {
      this.setState({microCategoryId: responseJson.data?.attributes?.micro_category?.id})
      this.fetchExistingGroupList();
      this.fetchExistingVariantList();
    }
  }

  getToken = async () => {
    const token = await getStorageData("authToken");
    this.setState({ token });
  };

  validateSKU = (indexValue: number) => {
    this.setState({ isValidating: true });
    const headers = {
      "Content-Type": "application/json"
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiValidateSKUCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/sku_validate?sku=${this.state.skuValue[indexValue]}`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSelectGroup = (groupData: IExistingGroupList, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.checked) {
      this.setState({ selectedExistingGroupData: groupData, errorMsg: "", validateError: "" });
    } else {
      this.setState({ selectedExistingGroupData: null, errorMsg: "", validateError: "" });
    }
  };

  handleDeleteGroupResponse = (responseJson: IResponseData, errorResponse: IErrorResponse) => {
    this.setState({ loading: false });
    if ("errors" in responseJson || errorResponse) {
      const key2 = Object.keys(responseJson.errors[0])[0];
      toast.error(responseJson?.errors?.[0]?.[key2] ? `${JSON.stringify(responseJson.errors[0][key2])}` : "Failed to delete group. Please try again later!", {
        draggable: true,
        pauseOnHover: true,
        closeOnClick: true,
        autoClose: 3000,
        progress: undefined,
        hideProgressBar: false,
        position: "top-right"
      });
      return;
    }
    if (responseJson) {
      toast.success("Group deleted successfully!", {
        position: "top-right",
        pauseOnHover: true,
        hideProgressBar: false,
        draggable: true,
        closeOnClick: true,
        autoClose: 3000, // milliseconds
        progress: undefined
      });
      this.fetchExistingGroupList();
      this.handleCloseDeleteModal();
    }
  };

  handleDeleteVariantResponse = (responseJson: IResponseData, errorResponse: IErrorResponse) => {
    this.setState({ loading: false });
    if ("errors" in responseJson || errorResponse) {
      const key1 = Object.keys(responseJson.errors[0])[0];
      toast.error(responseJson?.errors?.[0]?.[key1] ? `${JSON.stringify(responseJson.errors[0][key1])}` : "Failed to delete variant. Please try again later!", {
        autoClose: 3000,
        draggable: true,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        pauseOnHover: true,
        position: "top-right"
      });
      return;
    }
    if (responseJson) {
      toast.success("Variant deleted successfully!", {
        position: "top-right",
        autoClose: 3000, // milliseconds
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false,
        draggable: true,
        progress: undefined
      });
      this.fetchExistingVariantList();
      this.handleCloseDeleteVariantModal();
    }
  };

  handleSKUOnChangeInputs = (event: React.ChangeEvent<HTMLInputElement>, indexValue: number) => {
    const value1 = event.target.value;
    let tempIsValidate = this.state.isValidate;
    let tempSkuValue = this.state.skuValue;
    let validateErr = this.state.skuValidateErr;
    let validateErrMsg = this.state.validateErrMsg;
    validateErr[indexValue] = '';
    tempIsValidate[indexValue] = false;
    if (value1) {
      validateErrMsg[indexValue] = "Please validate sku";
      const isValueValid = /^[a-zA-Z0-9 ]+$/.test(value1);
      if (isValueValid) {
        tempSkuValue[indexValue] = value1;
        this.setState({ skuValidateErr: validateErr,skuValue: tempSkuValue, isValidate: tempIsValidate, validateErrMsg:validateErrMsg });
      }
    } else {
      validateErrMsg[indexValue] = "";
      tempSkuValue[indexValue] = "";
      this.setState({ skuValidateErr: validateErr,isValidate: tempIsValidate, skuValue: tempSkuValue, validateErrMsg: validateErrMsg });
    }
  };

  handleValidates = (indexValue: number) => {
    let tempIsValidate = this.state.isValidate;
    if (this.state.skuValue[indexValue]) {
      tempIsValidate[indexValue] = true;
      this.setState({ isValidate: tempIsValidate, errorMsg: "", validateError: "", skuValidateIndex: indexValue });
      this.validateSKU(indexValue);
    } else {
      this.setState({ validateError: "" });
    }
  };

  handleValidateChildSKU = (responseJson: {message: string}) => {
    let tempIsValidate = this.state.isValidate;
    let tempskuValue = this.state.skuValue;
    let validateErrMsg = this.state.validateErrMsg
    let validateErr:string[] = this.state.skuValidateErr
    this.setState({ isValidating: false });
    if (responseJson.message === "SKU is valid") {
      tempIsValidate[this.state.skuValidateIndex] = true;
      validateErr[this.state.skuValidateIndex]="SKU is valid";
      validateErrMsg[this.state.skuValidateIndex] = ""
      this.setState({ isSkuValid: tempIsValidate, errorMsg: "", skuValidateErr: validateErr, validateErrMsg: validateErrMsg });
      SetCookies("childSku", tempskuValue[this.state.skuValidateIndex], 7);
    } else if (responseJson.message === configJSON.alreadyExistsText) {
      tempIsValidate[this.state.skuValidateIndex] = false;
      validateErrMsg[this.state.skuValidateIndex] = `${tempskuValue[this.state.skuValidateIndex]} already validated, please enter a new one to validate.`
      validateErr[this.state.skuValidateIndex]=configJSON.alreadyExistsText
      this.setState({ isSkuValid: tempIsValidate, errorMsg: "" , skuValidateErr:validateErr, validateErrMsg: validateErrMsg});
    }
  };

  handleDeleteIcon = (indexValue: number) => {
    let tempIsValidate = this.state.isValidate;
    let validateErr = this.state.skuValidateErr;
    let validateErrMsg = this.state.validateErrMsg;
    tempIsValidate[indexValue] = false;
    let tempisSkuValid = this.state.isSkuValid;
    tempisSkuValid[indexValue] = false;
    let tempskuValue = this.state.skuValue;
    tempskuValue[indexValue] = "";
    validateErr[indexValue] = "";
    validateErrMsg[indexValue] = "";
    this.setState({ skuValidateErr: validateErr,isValidate: tempIsValidate, isSkuValid: tempisSkuValid, skuValue: tempskuValue, skuValidateIndex: -1, validateErrMsg: validateErrMsg });
    SetCookies("childSku", "", 7);
  };

  handleGroupDetailsApiCall = async (groupId: string) => {
    this.setState({ loading: true });
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getGroupDetailsId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.variant.getGroupDetails(groupId));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchExistingGroupList = async () => {
    this.setState({ loading: true });
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getExistingGroupListId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.variant.listAllGroup(this.state.microCategoryId));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchExistingVariantList = async () => {
    const catalogId = await getStorageData("catalog_id");
    this.setState({ loading: true });
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getExistingVariantListId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.variant.listProductVariants(catalogId));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleExistinGroupListApiResponse = (responseJson: IExistingGroupListRes) => {
    this.setState({ loading: false });
    if (responseJson && responseJson.data && responseJson.data?.length > 0) {
      this.setState({ activeStep: "groupList", existingGroupList: responseJson.data });
    } else {
      this.setState({ existingGroupList: [] });
      if (this.state.existingVariantList?.length === 0) {
        this.setState({ activeStep: "variantHome" });
      } else {
        this.setState({ activeStep: "groupList" });
      }
    }
  };

  handleExistinVariantListApiResponse = (responseJson: IExistingVariantListRes) => {
    this.setState({ loading: false });
    if (responseJson && responseJson.data && responseJson.data?.length > 0) {
      this.setState({ activeStep: "groupList", existingVariantList: responseJson.data });
    } else {
      this.setState({ existingVariantList: [] });
      if (this.state.existingGroupList?.length === 0) {
        this.setState({ activeStep: "variantHome" });
      } else {
        this.setState({ activeStep: "groupList" });
      }
    }
  };

  handleGroupDetailsApiCallResponse = (responseJson: any, errorJson: IErrorResponse) => {
    this.setState({ loading: false });
    if (responseJson && responseJson.data) {
      const selectGroupData = responseJson?.data?.attributes?.variant_attributes?.map((el: {attribute_options:{option: string}[]}) => {
        return { ...el, option: el.attribute_options?.[0]?.option || "" };
      });
      this.setState({ selectGroupData });
    } else {
      this.setState({ selectGroupData: [] });
      this.parseApiCatchErrorResponse(errorJson);
    }
  };

  handleCheckBoxChange = (indexValue:number, checked: boolean) => {
    const checkedValue = checked;
    if (indexValue === -1) {
      const uncheckAllAttribute = this.state.attributesList.map(item => ({ ...item, checked: false }));
      this.setState({ allAttributeValue: checkedValue });
      this.setState({ attributesList: [...uncheckAllAttribute] });
    } else {
      this.setState({ allAttributeValue: false });
      const updateList = [...this.state.attributesList];
      updateList[indexValue] = { ...this.state.attributesList[indexValue], checked: checkedValue };
      this.setState({ attributesList: updateList });
    }
  };

  handleAddAttributes = (values: any) => {
    this.setState({createGroupErr: ''})
    const attributes = values.attributes?.map((item: any) => {
      return {
        ...item,
        attribute_options_attributes: item.attribute_options_attributes.map((element: string) => {
          return { option: element };
        })
      };
    });
    this.setState(prev => {
      return { ...prev, attributesList: [...prev.attributesList, ...values.attributes] };
    });
    this.setState({ addAttribute: false });
  };

  handleCreateGroupResponse = async (responseJson: any, errorResponse: IErrorResponse) => {
    this.setState({ loading: false });
    if ("data" in responseJson) {
      toast.success(this.state.selectedExistingGroupData ? "Group updated successfully!" : "Group created successfully!", {
        progress: undefined,
        autoClose: 3000, // milliseconds
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        pauseOnHover: true
      });
      if (this.state.selectedExistingGroupData) {
        await this.fetchExistingGroupList();
        await this.fetchExistingVariantList();
        this.setState({ activeStep: "groupList", initialAttributesFormList: [{ attribute_name: "", attribute_options_attributes: [""] }], addAttribute: false, selectGroupId: "", selectedExistingGroupData: null, attributesList: [], skuValue: [], isValidate: [] });
      } else {
        await this.handleGroupDetailsApiCall(responseJson?.data.id);
        this.setState({ activeStep: "createNewVariant", addAttribute: false, selectGroupId: responseJson?.data.id, attributesList: [], skuValue: [], isValidate: [] });
      }
    }
    if ("errors" in responseJson || errorResponse) {
      toast.error(responseJson?.errors ? `${JSON.stringify(responseJson.errors)}` : "Failed !!. Please try again later!", {
        draggable: true,
        progress: undefined,
        autoClose: 3000, // milliseconds
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false,
        position: "top-right"
      });
    }
  };

  handleCreateVariantResponse = async (responseJson: any, errorResponse: IErrorResponse) => {
    this.setState({ loading: false, validateErrMsg: [], skuValidateErr: [] });
    if ("data" in responseJson) {
      toast.success(this.state.selectedExistingVariantData ? "Variant updated successfully!" : "Variant created successfully!", {
        progress: undefined,
        autoClose: 3000, // milliseconds
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        pauseOnHover: true
      });

      await this.fetchExistingGroupList();
      await this.fetchExistingVariantList();
      this.setState({ activeStep: "groupList", selectGroupData: [], addAttribute: false, selectGroupId: "", selectedExistingVariantData: null, attributesList: [], product_sku: "", skuValue: [], isValidate: [] });
    }
    if ("errors" in responseJson || errorResponse) {
      toast.error(responseJson?.errors ? `${JSON.stringify(responseJson.errors)}` : "Something went wrong!!. Please try again later!", {
        draggable: true,
        progress: undefined,
        autoClose: 3000, // milliseconds
        closeOnClick: true,
        pauseOnHover: true,
        hideProgressBar: false,
        position: "top-right"
      });
    }
  };

  concatArraysWithoutDuplicates(array1: any[], array2: any[]) {
    const first = array2?.map((obj: any) => {
      let finalOptions: any = [];
      let optionsWhichRemoved = array1?.find((xy: any) => xy?.id === obj?.id)?.attribute_options?.filter((el: any) => !obj?.attribute_options_attributes?.some((item: any) => item?.id === el?.id));

      if (obj?.attribute_options_attributes && Array.isArray(obj?.attribute_options_attributes) && obj?.attribute_options_attributes?.length > 0) {
        finalOptions = [...obj?.attribute_options_attributes];
      }

      if (optionsWhichRemoved && Array.isArray(optionsWhichRemoved) && optionsWhichRemoved?.length > 0) {
        optionsWhichRemoved = optionsWhichRemoved?.map((option: any) => {
          return {
            ...option,
            _destroy: true
          };
        });

        finalOptions = [...finalOptions, ...optionsWhichRemoved];
      }

      return {
        ...obj,
        _destroy: this.state.allAttributeValue ? false : !obj?.checked,
        attribute_options_attributes: finalOptions
      };
    });
    const second =
      array1
        ?.filter((obj: {id: string}) => !array2?.some(item => item?.id === obj?.id))
        ?.map((el: any) => {
          return {
            ...el,
            _destroy: true,
            checked: true
          };
        }) || [];

    return [...first, ...second];
  }

  handleCreateGroup = async (values: any) => {
    const { attributesList, allAttributeValue } = this.state;

    let attributesData = [];

    if (this.state.selectedExistingGroupData) {
      const tempData = [...attributesList];
      attributesData = this.concatArraysWithoutDuplicates(this.state.selectedExistingGroupData?.attributes?.variant_attributes, tempData);
    } else {
      attributesData = [...attributesList];
    }

    if (allAttributeValue || this.state.selectedExistingGroupData) {
      attributesData = [...attributesData?.map(({ checked, ...rest }) => rest)];
    } else {
      attributesData = attributesList.filter(({ checked }) => checked).map(({ checked, ...rest }) => rest);
    }

    const body = {
      data: {
        attributes: {
          micro_category_id: this.state.microCategoryId,
          group_name: values.group_name,
          variant_attributes_attributes: attributesData ? attributesData : []
        }
      }
    };
    const headers = { token: this.state.token, "Content-Type": "application/json" };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiPostCreateGroupId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

    if (this.state.selectedExistingGroupData) {
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.variant.createGroup(this.state.selectedExistingGroupData?.id));
    } else {
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.variant.createGroup());
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCreateVariantWithGroup = async (values: any) => {
    const catalog_id = await getStorageData("catalog_id");
    const { selectGroupId } = this.state;
    const catalogue_variant_id = selectGroupId;
    const groupData = values?.variantFormArray?.map((item: any) => {
      const group_attributes_attributes = item?.group_attributes_attributes?.map(({ id, attribute_options, option,  ...rest }: any) => {
        const optionId = attribute_options.map((item:{id:string, option:string}) => {
          if(item.option === option) {
            return item.id
          }
        })
        return { ...rest, variant_attribute_id: id,  attribute_option_id: optionId[0],option: option };
      });
      const product_sku = item?.product_sku;
      return {
        catalogue_variant_id,
        product_sku,
        group_attributes_attributes
      };
    });
    const headers = { token: this.state.token, "Content-Type": "application/json" };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiPostCreateVariantId = requestMessage.messageId;
    if (this.state.selectedExistingVariantData) {
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.variant.updateVariantWithGroup(catalog_id, this.state.selectedExistingVariantData?.id));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(groupData[0]));
    } else {
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.variant.createVariantWithGroup(catalog_id));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(groupData));
    }
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteGroupHandler = async () => {
    this.setState({ loading: true });
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteGroupId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.variant.deleteGroup(this.state.selectedForDeleteId));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEditGroup = (item: any) => {
    this.setState({
      activeStep: "createNewGroup",
      selectedExistingGroupData: item,
      addAttribute: item?.attributes?.variant_attributes?.length > 0,
      initialAttributesFormList: item?.attributes?.variant_attributes?.map((item: any) => {
        return {
          id: item?.id,
          attribute_name: item?.attribute_name,
          attribute_options_attributes: item.attribute_options
        };
      })
    });
  };

  deleteVariantHandler = async () => {
    const catalog_id = await getStorageData("catalog_id");
    this.setState({ loading: true });
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteVariantId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.variant.deleteVariant(catalog_id, this.state.selectedVariantDeleteId));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAddNewVariant = () => {
    if (this.state.selectedExistingGroupData) {
      const selectGroupData = this.state.selectedExistingGroupData?.attributes?.variant_attributes?.map((el: any) => {
        return { ...el, option: el.attribute_options?.[0]?.option || "" };
      });
      this.setState({ loading: true, selectGroupData, selectGroupId: this.state.selectedExistingGroupData?.id }, () => {
        this.setState({ activeStep: "createNewVariant", selectedVariantDeleteId: "", loading: false });
      });
    } else {
      this.setState({ errorMsg: "Please select group to proceed." });
    }
  };

  handleEditVariant = (item: any) => {
    this.setState({skuValue: [item?.attributes?.product_sku] })
    const selectGroupData = item?.attributes?.catalogue_variant?.data?.attributes?.variant_attributes?.map((el: any) => {
      const optionDefaultSelected = item?.attributes?.individual_group_attr_with_ids?.find((tr: any) => tr?.attribute_name === el?.attribute_name)?.options || el?.attribute_options?.[0]?.option;
      const optionSelectedId = item?.attributes?.individual_group_attr_with_ids?.find((tr: any) => tr?.attribute_name === el?.attribute_name)?.id;
      return {
        ...el,
        option: optionDefaultSelected,
        optionId: optionSelectedId
      };
    });

    this.setState({
      activeStep: "createNewVariant",
      selectGroupData,
      product_sku: item?.attributes?.product_sku,
      selectedExistingVariantData: item,
      selectGroupId: item?.attributes?.catalogue_variant_id
    });
  };

  handleEditGroupPage = () => {
    const msg: Message = new Message(getName(MessageEnum.SendMessage));
    msg.addData(getName(MessageEnum.ChangeActivePanelProductDetails), { activePanel: "barcode", activeScreen: "barcode" });
    this.send(msg);
  };

  handleCreateNewGroup = () => {
    this.setState({ activeStep: "createNewGroup", selectedExistingGroupData: null, errorMsg: "", attributesList: [], initialAttributesFormList: [{ attribute_name: "", attribute_options_attributes: [""] }] });
  };

  handleOptionChange = (handleChange: any, setFieldTouched: any, ind: number, indexValue: number, event: React.ChangeEvent<{ name?: string ; value: unknown; }>) => {
    handleChange(event);
    setFieldTouched(`variantFormArray[${ind}].group_attributes_attributes[${indexValue}].option`, true, false);
  };

  handleOptionsNameChange = (handleChange: any, setFieldTouched: any, ind: number, optionIndex: number, event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
    setFieldTouched(`attributes[${ind}].attribute_options_attributes[${optionIndex}].option`, true, false);
  };

  handleGroupNameChange = (handleChange: any, setFieldTouched: any, event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
    setFieldTouched("group_name", true, false);
  };

  handleOpenAddAttributeForm = () => {
    this.setState({ addAttribute: true, initialAttributesFormList: [{ attribute_name: "", attribute_options_attributes: [""] }] });
  };

  handleOpenDeleteModal = (id: unknown) => {
    this.setState({ openDeleteModal: true, selectedForDeleteId: id as string });
  };

  handleOpenDeleteVariantModal = (id: unknown) => {
    this.setState({ openVariantDeleteModal: true, selectedVariantDeleteId: id as string });
  };

  handlebackToHomePage = () => {
    if (this.state.existingGroupList?.length > 0 || this.state.existingVariantList?.length > 0) {
      this.fetchExistingGroupList();
      this.fetchExistingVariantList();
      this.setState({ activeStep: "groupList", attributesList: [], addAttribute: false, initialAttributesFormList: [{ attribute_name: "", attribute_options_attributes: [""] }], skuValue: [], isValidate: [], selectGroupId: "", product_sku: "", selectedExistingGroupData: null, selectedExistingVariantData: null });
    } else {
      this.setState({ activeStep: "variantHome", attributesList: [], addAttribute: false, initialAttributesFormList: [{ attribute_name: "", attribute_options_attributes: [""] }], skuValue: [], isValidate: [], selectGroupId: "", product_sku: "", selectedExistingGroupData: null, selectedExistingVariantData: null });
    }
  };

  handleCloseDeleteModal = () => {
    this.setState({ openDeleteModal: false, selectedForDeleteId: "" });
  };

  handleCloseDeleteVariantModal = () => {
    this.setState({ openVariantDeleteModal: false, selectedVariantDeleteId: "" });
  };

  validationSchemaChildSku = Yup.object().shape({
    variantFormArray: Yup.array().of(
      Yup.object().shape({
        product_sku: Yup.string()
          .required("Please create child SKU")
          .min(2, "SKU must be at least 2 characters")
          .max(16, "SKU must be at most 16 characters")
          .matches(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/, "SKU must be alphanumeric")
          .matches(/^\S*$/, "Whitespace is not allowed"),
        group_attributes_attributes: Yup.array().of(
          Yup.object().shape({
            attribute_name: Yup.string().required("Attribute name is required"),
            option: Yup.string().required("Please select option")
          })
        )
      })
    )
  });

  validationSchema = Yup.object().shape({
    group_name: Yup.string()
      .trim()
      .required("Group name is required")
      .max(30, "Group name must not exceed 30 characters")

  });

  validationSchemaAddAttribute = Yup.object().shape({
    attributes: Yup.array().of(
      Yup.object().shape({
        attribute_name: Yup.string()
          .required("Attribute name is required")
          .matches(/^(?=.*[a-zA-Z0-9])[a-zA-Z0-9 !@#$%^&*()_+={}\[\]:;"'<>,.?/-]*$/, "Attribute name should be alphanumeric")
          .max(30, "Attribute name must not exceed 30 characters"),

        attribute_options_attributes: Yup.array().of(
          Yup.object().shape({
            option: Yup.string()
              .required("Option is required")
              .matches(/^(?=.*[a-zA-Z0-9])[a-zA-Z0-9 !@#$%^&*()_+={}\[\]:;"'<>,.?/-]*$/, "Attribute name should be alphanumeric")
              .max(30, "Option must not exceed 30 characters")
          })
        )
      })
    )
  });
  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
}
