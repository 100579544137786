import React from "react";

// Customizable Area Start
import { FormControlLabel, Grid, IconButton, Select, TableBody, TableCell, TableContainer, TableHead, TableRow, Table, Box, InputAdornment, Checkbox, Typography, withStyles, Theme, Chip, Radio, Modal } from "@material-ui/core";
import { CustomMenuItem, CustomExpandMoreIcon, AddNewProductButton, CustomizeBrandInput, OrangeButton, CustomFormControl, BlackButton, CustomBackButton, CustomDeleteButton, FitContentOrangeButton, FitContentDarkButton, RadioCheckbox } from "../CustomizedElementStyle";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { ToastContainer } from "react-toastify";

export interface IAttribute {
  attribute_options_attributes: {
    option: string,
    id: string
  }[]
}

interface FormValues {
  variantFormArray: {
    product_sku: string;
    group_attributes_attributes: { attribute_name: string; option: string }[];
  }[];
}
// Customizable Area End

import VariantController, { IAttributesList, IExistingGroupList, IExistingVariantList, Props, configJSON } from "./VariantController";
import { Field, FieldArray, Form, Formik, FormikErrors, FormikTouched } from "formik";
import { deleteIcon } from "../../../catalogue/src/assets";
import { Loader2 } from "../../../../components/src/Loader.web";
import { editIcon } from "../assets";

class Variant extends VariantController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  returnErrorMessageVariant = (touched: boolean | string | undefined,errors: string |undefined) => {
    if (touched && errors) {
      return <Typography className={this.props.classes.errorStyle}>{errors}</Typography>;
    } else {
      return null;
    }
  };

  deletModal = () => {
    return (
      <Modal open={this.state.openDeleteModal} data-test-id="deleteModalRef">
        <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center " }} style={{ background: "rgba(0,0,0,0.1)" }}>
          <Box sx={{ width: "300px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", borderRadius: "8px", padding: "50px" }} style={{ background: "white" }}>
            <Box sx={{ marginBottom: "20px", textAlign: "center", fontFamily: `"Lato", sans-serif` }}>Are you sure you want to delete this Variant group?</Box>
            <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", gap: "15px" }}>
              <CustomBackButton data-test-id="backRef" onClick={() => this.handleCloseDeleteModal()} style={{ fontFamily: "Lato, sans-serif" }}>
                Back
              </CustomBackButton>
              <CustomDeleteButton data-test-id="deleteConfirmRef" onClick={this.deleteGroupHandler} style={{ fontFamily: "Lato, sans-serif" }}>
                Delete
              </CustomDeleteButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  deletModalVariant = () => {
    return (
      <Modal open={this.state.openVariantDeleteModal} data-test-id="deleteModalRef">
        <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center " }} style={{ background: "rgba(0,0,0,0.1)" }}>
          <Box sx={{ width: "300px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", borderRadius: "8px", padding: "50px" }} style={{ background: "white" }}>
            <Box sx={{ marginBottom: "20px", textAlign: "center", fontFamily: `"Lato", sans-serif` }}>Are you sure you want to delete this Variant ?</Box>
            <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", gap: "15px" }}>
              <CustomBackButton data-test-id="backRef" onClick={() => this.handleCloseDeleteVariantModal()} style={{ fontFamily: "Lato, sans-serif" }}>
                Back
              </CustomBackButton>
              <CustomDeleteButton data-test-id="deleteConfirmRef" onClick={this.deleteVariantHandler} style={{ fontFamily: "Lato, sans-serif" }}>
                Delete
              </CustomDeleteButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  pageTorender = () => {
    switch (this.state.activeStep) {
      case "variantHome":
        return this.variantHomePage();

      case "groupList":
        return this.listingPage();

      case "createNewGroup":
        return this.createGroupForm();

      case "createNewVariant":
        return this.createVariantForm();

      default:
        return <></>;
    }
  };

  handleRemoveAttribute = (index: number, optionIndex: number, setFieldValue: (field: string, value: unknown) => void, remove: (index: number) => void, attribute: IAttribute, attributeList:IAttribute[]) => {
    if (optionIndex === 0) {
      return (
        <IconButton
          data-test-id="removeAttributeButtonRef"
          id="modalClose"
          style={{ marginTop: "24px" }}
          onClick={() => {
            remove(index);
            if (index === 0 && attributeList?.length === 1) {
              this.setState({ addAttribute: false });
            }
          }}
        >
          <IndeterminateCheckBoxIcon style={{ color: "red" }} />
        </IconButton>
      );
    } else {
      return (
        <IconButton
          data-test-id="removeOptionButtonRef"
          style={{ marginTop: "-5px" }}
          onClick={() => {
            const newOptions = attributeList.filter((_: unknown, i: number) => i === index);
            const updatedOptions = newOptions[0].attribute_options_attributes.filter((_:unknown, index:number) => index !== optionIndex)
            setFieldValue(`attributes[${index}].attribute_options_attributes`, updatedOptions)
          }}
        >
          <DeleteIcon />
        </IconButton>
      );
    }
  };

  handleRemoveVariants = (variantIndex: number, remove: (index: number) => void) => {
    if (variantIndex > 0) {
      return (
        <IconButton data-test-id="removeVariantButtonRef" id="modalClose" style={{ marginTop: "24px" }} onClick={remove.bind(this, variantIndex)}>
          <IndeterminateCheckBoxIcon style={{ color: "red" }} />
        </IconButton>
      );
    } else {
      return <></>;
    }
  };

  variantHomePage = () => {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          // marginTop: "25px",
          flex: 1,
          alignItems: "center"
        }}
      >
        <AddNewProductButton onClick={this.handleCreateNewGroup} style={{ fontFamily: "Lato, sans-serif", padding: "18px 25px", marginRight: "20px", fontSize: "20px", height: "fit-content", fontWeight: 600 }} data-test-id="createNewGroupRef">
          <AddIcon style={{ color: "white", marginRight: 10, marginLeft: 0, scale: "1.3" }} />
          Create a new group
        </AddNewProductButton>
      </Box>
    );
  };
  createVariantForm = () => {
    const { classes } = this.props;
    const { selectGroupData, product_sku, skuValue, isValidate } = this.state;
    return (
      <Formik enableReinitialize={true} data-test-id="createVariantFormRef" validateOnChange={true} validateOnBlur={true} validateOnMount={true} validationSchema={this.validationSchemaChildSku} initialValues={{ variantFormArray: [{ product_sku: product_sku , isValidate: isValidate,group_attributes_attributes: selectGroupData }] }} 
      onSubmit={(values:any) => {
        if(!this.state.isValidate.includes(false)) {
          this.handleCreateVariantWithGroup(values)
        }
      }}>
        {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
          <Form translate={undefined} className={classes.addVariantFormikWrraper}>
            <FieldArray name="variantFormArray" data-test-id="formikVariantCreateGroupFormRef">
              {({ remove, push }) => (
                <>
                  {values?.variantFormArray.map((variantListItems: {product_sku: string | any}, ind: number) => {
                    const erroMessageName = (errors?.variantFormArray as FormikErrors<{product_sku:string}>[])?.[ind]
                    const touchedMessageName = (touched?.variantFormArray as FormikTouched<{product_sku:boolean}>[] )?.[ind] ;
                    return (
                      <>
                        <Grid container spacing={2}>
                          {ind > 0 && <Box style={{ width: "100%", margin: "30px 0", borderBottom: "1px solid rgba(0, 0, 0, 0.03)" }}></Box>}
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            <Field name={`variantFormArray[${ind}].product_sku`} data-test-id="formFieldcreateSKU">
                              {(atr: {field: {}}) => (
                                <CustomFormControl fullWidth={true}>
                                  <label id={"Add child sku"} className={classes.labelHeader}>
                                    {"Add child sku"}
                                  </label>
                                  <CustomizeBrandInput
                                    {...atr.field}
                                    data-test-id="createSKU"
                                    placeholder="Enter child SKU"
                                    label={""}
                                    id={`variantFormArray[${ind}].product_sku`}
                                    value={values.variantFormArray[ind].product_sku}
                                    className={classes.sku}
                                    autoComplete="off"
                                    fullWidth
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                      this.handleSKUOnChangeInputs(event,ind)
                                      handleChange(event);
                                      setFieldTouched("product_sku", true, false);
                                    }}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start"></InputAdornment>
                                    }}
                                  />
                                  <Typography data-test-id="errorText" className={this.props.classes.errorStyle}>{this.state.validateErrMsg[ind]}</Typography>
                                  {this.returnErrorMessageVariant(touchedMessageName?.product_sku as boolean, erroMessageName?.product_sku)}
                                </CustomFormControl>
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={10} sm={4} md={4} lg={4}>
                            <FitContentDarkButton data-test-id="validateBtn" disabled={!!erroMessageName?.product_sku || this.state.isValidating} className={classes.validateBtn} onClick={event => {this.handleValidates(ind) 
                            }} style={{ fontFamily: "Lato, sans-serif" }}>
                              validate
                            </FitContentDarkButton>
                          </Grid>
                          <Grid item xs={2} sm={4} md={4} lg={4} style={{ textAlign: "right" }}>
                          {this.handleRemoveVariants(ind, remove)}
                          </Grid>
                        </Grid>
                        {variantListItems.product_sku &&
                        <>
                          {this.state.skuValidateErr?.[ind] === "SKU is valid" && 
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={4} md={4} lg={4}>
                                {this.state.isSkuValid?.[ind] && <Chip data-test-id="check" icon={<CheckCircleOutlineIcon className={classes.check} />} label={variantListItems.product_sku} className={classes.checkWrap} />}
                              </Grid>
                              <Grid item xs={12} sm={4} md={4} lg={4} className={classes.deleteWrap}>
                                <img data-test-id="deleteIcon-valid" onClick={() => {
                                  setFieldValue(`variantFormArray[${ind}].product_sku`,'')
                                  this.handleDeleteIcon(ind)
                                }} src={deleteIcon} alt="delIcon" style={{ cursor: "pointer" }} />
                              </Grid>
                            </Grid>
                          }
                        {this.state.skuValidateErr?.[ind] === configJSON.alreadyExistsText &&
                          <Grid container spacing={2}>
                          <Grid item xs={12} sm={4} md={4} lg={4}>
                            { <Chip data-test-id="cancel" icon={<CancelOutlinedIcon className={classes.cancel} />} label={variantListItems.product_sku} className={classes.cancelWrap} />}
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={4} className={classes.deleteWrap}>
                            <img data-test-id="deleteIcon-invalid" onClick={() => {
                              setFieldValue(`variantFormArray[${ind}].product_sku`,'')
                              this.handleDeleteIcon(ind)
                            }} src={deleteIcon} alt="delIcon" style={{ cursor: "pointer" }} />
                          </Grid>
                        </Grid>
                        }
                        </>
                  }
                        <FieldArray name="group_attributes_attributes">
                          {() => (
                            <>
                              {values?.variantFormArray?.[ind].group_attributes_attributes?.length > 0 && (
                                <Box className="selected-attribute-wrraper">
                                  <Box className="select-title">Create Group Attributes</Box>
                                  {values.variantFormArray[ind]?.group_attributes_attributes?.map((item: {attribute_name: string, option: string, attribute_options: {id: string, option:string}[]}, index: number) => (
                                    <>
                                      <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                          <Field name={`variantFormArray[${ind}].group_attributes_attributes[${index}].attribute_name`}>
                                            {(attr: {field: {}}) => (
                                              <CustomFormControl fullWidth={true}>
                                                <CustomizeBrandInput
                                                  {...attr.field}
                                                  data-test-id="attributeNameRef"
                                                  id={`variantFormArray[${ind}].group_attributes_attributes[${index}].attribute_name`}
                                                  defaultValue={item?.attribute_name}
                                                  value={item?.attribute_name}
                                                  disabled
                                                  label={`Select ${item?.attribute_name}`}
                                                  autoComplete="off"
                                                  fullWidth
                                                  InputLabelProps={{
                                                    style: {
                                                      whiteSpace: "nowrap",
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      width: "200px",
                                                      display: "inline-block"
                                                    }
                                                  }}
                                                  InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    classes: {
                                                      input: classes.attributeName,
                                                    }
                                                  }}
                                                />
                                              </CustomFormControl>
                                            )}
                                          </Field>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Field name={`variantFormArray[${ind}].group_attributes_attributes[${index}].option`}>
                                            {(attrc: {field: {}}) => (
                                              <CustomFormControl fullWidth={true}>
                                                <Select
                                                  {...attrc.field}
                                                  style={{ fontFamily: "Lato, sans-serif", height: "43px" }}
                                                  IconComponent={CustomExpandMoreIcon}
                                                  labelId="demo-simple-select-outlined-label"
                                                  placeholder={`Please select ${item?.attribute_name}`}
                                                  id="demo-simple-select-outlined"
                                                  data-test-id="specialFeatureRef"
                                                  defaultValue={item.option}
                                                  onChange={this.handleOptionChange.bind(this, handleChange, setFieldTouched, ind, index)}
                                                  onBlur={handleBlur}
                                                  MenuProps={{
                                                    anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "left"
                                                    },
                                                    transformOrigin: {
                                                      horizontal: "left",
                                                      vertical: "top"
                                                    },
                                                    getContentAnchorEl: null,
                                                    PaperProps: {
                                                      style: {
                                                        background: "#F6F6F6",
                                                        border: "1px solid rgba(151, 151, 151, 0.147084)",
                                                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0468298)",
                                                        marginTop: "12px",
                                                        maxHeight: "400px",
                                                        overflowY: "auto"
                                                      }
                                                    }
                                                  }}
                                                >
                                                  {item?.attribute_options?.map((el: {id: string, option: string}, index: number) => {
                                                    return (
                                                      <CustomMenuItem value={el?.option} data-test-id={`disperserType${index}`} key={el?.id}>
                                                        {el?.option}
                                                      </CustomMenuItem>
                                                    );
                                                  })}
                                                </Select>
                                              </CustomFormControl>
                                            )}
                                          </Field>
                                        </Grid>
                                      </Grid>
                                    </>
                                  ))}
                                </Box>
                              )}
                            </>
                          )}
                        </FieldArray>
                      </>
                    );
                  })}
                  {!this.state.selectedExistingVariantData && (
                    <Grid container justifyContent="flex-end" alignItems="center">
                      <AddNewProductButton data-test-id="addMorevariantBtnRef" style={{ fontSize: "16px" }} onClick={() => push({ product_sku: "", group_attributes_attributes: selectGroupData })}>
                        <AddIcon style={{ color: "white", marginRight: 10, marginLeft: 0 }} />
                        Add more Variants
                      </AddNewProductButton>
                    </Grid>
                  )}
                  <Box className={"next-button"}>
                    <BlackButton data-test-id="backBtnCreateSku" style={{ fontFamily: "Lato, sans-serif", fontWeight: 400, padding: "6px 50px", borderRadius: 4, fontSize: 16 }} onClick={this.handlebackToHomePage}>
                      Back
                    </BlackButton>
                    <FitContentOrangeButton data-test-id="submitAttributeBtnRef" type="submit" style={{ fontFamily: "Lato, sans-serif", padding: "6px 50px", borderRadius: 4, fontSize: 16 }}>
                      {this.state.selectedExistingVariantData ? "Update" : "Next"}
                    </FitContentOrangeButton>
                  </Box>{" "}
                </>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    );
  };
  createGroupForm = () => {
    const { classes } = this.props;
    const { addAttribute } = this.state;
    return (
      <Box
        style={{
          height: "100%"
        }}
      >
        <Formik
          initialValues={{
            group_name: this.state.selectedExistingGroupData?.attributes?.group_name
          }}
          enableReinitialize={true}
          validationSchema={this.validationSchema}
          validateOnMount={true}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={(values:any) => {
            if(this.state.attributesList.length > 0) {
              this.setState({createGroupErr: ""})
              this.handleCreateGroup(values)
            }
            else this.setState({createGroupErr: "Please Add Attributes"})
          }}
          data-test-id="formikCreateGroupRef"
        >
          {({ handleChange, errors, setFieldTouched, touched, handleBlur, values, setErrors }) => (
            <Form
              translate={undefined}
              style={{
                margin: 0,
                height: !addAttribute ? "100%" : "auto"
              }}
              // className={classes.formWrraper}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: !addAttribute ? "100%" : "auto"
                }}
              >
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomFormControl fullWidth={true}>
                      <label id={"Select group name"} className={classes.labelHeader}>
                        {"Select group name"}
                      </label>
                      <CustomizeBrandInput
                        label=""
                        placeholder="Enter group name"
                        data-test-id="groupNameRef"
                        id="outlined-start-adornment"
                        InputProps={{
                          startAdornment: <InputAdornment position="start"></InputAdornment>
                        }}
                        name="group_name"
                        value={values.group_name}
                        onChange={this.handleGroupNameChange.bind(this, handleChange, setFieldTouched)}
                        onBlur={handleBlur}
                      />
                      {this.returnErrorMessageVariant(touched.group_name as boolean, errors.group_name as string)}
                    </CustomFormControl>
                  </Grid>
                  <Grid item xs={12} className={classes.selectGroupWrraper}>
                    <Box className="list-container">
                      {this.state.attributesList && this.state.attributesList.length > 0 && (
                        <Box style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                          <Box className="select-title" style={{ fontWeight: 600 }}>
                            Select Group attributes
                          </Box>
                          <Box className="attribute-item-container">
                            <FormControlLabel
                              label={"All"}
                              name="all"
                              data-test-id="allCheckboxRef"
                              className={this.state.allAttributeValue ? "checkbox-selected" : "checkbox-unselected"}
                              onChange={(event: React.ChangeEvent<{}>, checked: boolean) => this.handleCheckBoxChange(-1, checked)}
                              control={<Checkbox data-test-id="fulfillmentTypeFilter" checked={this.state.allAttributeValue} className={classes.delivered} />}
                              style={{ marginRight: "15px" }}
                            />
                            {this.state.attributesList.map((item: IAttributesList, index: number) => {
                              return (
                                <FormControlLabel
                                  label={item.attribute_name}
                                  name={item.attribute_name}
                                  className={item.checked ? "checkbox-selected" : "checkbox-unselected"}
                                  data-test-id="attributeCheckboxRef"
                                  onChange={(event: React.ChangeEvent<{}>, checked: boolean) => this.handleCheckBoxChange(index, checked)}
                                  control={item.checked ? <Checkbox data-test-id="checkboxIfSelectedRef" defaultChecked={true} checked={true} className={classes.delivered} /> : <Checkbox data-test-id="fulfillmentTypeFilter" defaultChecked={true} checked={false} className={classes.delivered} />}
                                  style={{ marginRight: "15px" }}
                                />
                              );
                            })}
                            {!addAttribute && (
                              <AddNewProductButton onClick={this.handleOpenAddAttributeForm} style={{ height: "fit-content", alignSelf: "flex-end", fontSize: "16px", margin: "10px 0", fontWeight: 600 }} data-test-id="addAttributeButtonRef">
                                <AddIcon style={{ color: "white", marginRight: 10, marginLeft: 0 }} />
                                Add attribute
                              </AddNewProductButton>
                            )}
                          </Box>
                        </Box>
                      )}
                      {!addAttribute && this.state.attributesList.length === 0 && (
                        <AddNewProductButton onClick={this.handleOpenAddAttributeForm} style={{ height: "fit-content", alignSelf: "flex-end", fontSize: "16px", margin: "10px 0", fontFamily: "Lato, sans-serif" }} data-test-id="addAttributeButtonRef">
                          <AddIcon style={{ color: "white", marginRight: 10, marginLeft: 0 }} />
                          Add attribute
                        </AddNewProductButton>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                {!addAttribute && (
                  <Box className={classes.confirmCancelButton}>
                    <Box style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                    {this.state.createGroupErr && (
            <Typography data-test-id="nextError" className={classes.nextError}>
              {this.state.createGroupErr}
            </Typography>
          )}
          </Box>
          <Box style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                    <BlackButton data-test-id="backBtn" className={classes.backButton} onClick={this.handlebackToHomePage}>
                      Cancel
                    </BlackButton>
                    <OrangeButton disabled={addAttribute} data-test-id="submitGroupBtn" type="submit" className={classes.submitButton}>
                      {this.state.selectedExistingGroupData ? "Update" : "Confirm"}
                    </OrangeButton>
                  </Box>
                  </Box>
                )}

              </Box>
            </Form>
          )}
        </Formik>

        {addAttribute && (
          <Formik validationSchema={this.validationSchemaAddAttribute} initialValues={{ attributes: this.state.initialAttributesFormList }} validateOnMount={true} validateOnChange={true} validateOnBlur={true} enableReinitialize={true} onSubmit={this.handleAddAttributes} data-test-id="formikAddAttribteFormRef">
            {({ values, setFieldValue, touched, errors, handleChange, setFieldTouched }) => (
              <Form translate={undefined} className={classes.addAttributeFormikWrraper}>
                <FieldArray name="attributes" data-test-id="formikCreateGroupFormRef">
                  {({ remove, push }) => (
                    <>
                      <Box className="select-title">Add attributes</Box>
                      {values?.attributes.map((attribute: IAttribute, index: number) => {
                        const erroMessageName:any = errors?.attributes?.[index];
                        const touchedMessageName = touched?.attributes?.[index];
                        const touchedMessageOption:any = (touched?.attributes && touched?.attributes?.[index]?.attribute_options_attributes) || [];
                        return (
                          <>
                            <Grid container spacing={2} key={`attributes[${index}].attribute_name` + index} style={{ marginTop: 0, marginBottom: 0 }}>
                              <Grid item xs={6}>
                                <Field name={`attributes[${index}].attribute_name`}>
                                  {(attr: {field: {}}) => (
                                    <CustomFormControl fullWidth={true}>
                                      <label id={"Enter Attribute name"} className={classes.labelHeader}>
                                        {"Enter Attribute name"}
                                      </label>
                                      <CustomizeBrandInput
                                        {...attr.field}
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                                          handleChange(event);
                                          setFieldTouched(`attributes[${index}].attribute_name`, true, false);
                                        }}
                                        placeholder="Enter Attribute name"
                                        label=""
                                        data-test-id="attributeNameRef"
                                        id={`attributes[${index}].attribute_name`}
                                        autoComplete="off"
                                        fullWidth
                                        InputProps={{
                                          startAdornment: <InputAdornment position="start"></InputAdornment>
                                        }}
                                      />

                                      {this.returnErrorMessageVariant(touchedMessageName?.attribute_name as boolean, erroMessageName?.attribute_name)}
                                    </CustomFormControl>
                                  )}
                                </Field>
                              </Grid>
                              {attribute.attribute_options_attributes &&
                                attribute.attribute_options_attributes.map((option: {option:string}, optionIndex: number) => {
                                  return (
                                    <>
                                      {optionIndex !== 0 && <Grid item xs={6}></Grid>}
                                      <Grid item xs={5}>
                                        <Field name={`attributes[${index}].attribute_options_attributes[${optionIndex}].option`}>
                                          {(attr: {field: {}}) => (
                                            <CustomFormControl fullWidth={true}>
                                              {optionIndex === 0 && (
                                                <label id={"optionNameRef"} className={classes.labelHeader}>
                                                  Options
                                                </label>
                                              )}
                                              <CustomizeBrandInput
                                                {...attr.field}
                                                data-test-id="optionNameRef"
                                                id="outlined-start-adornment"
                                                autoComplete="off"
                                                fullWidth
                                                label=""
                                                value={option?.option}
                                                placeholder="Enter option"
                                                onChange={this.handleOptionsNameChange.bind(this, handleChange, setFieldTouched, index, optionIndex)}
                                                InputProps={{
                                                  startAdornment: <InputAdornment position="start"></InputAdornment>
                                                }}
                                              />
                                              {this.returnErrorMessageVariant(touchedMessageOption[optionIndex], erroMessageName?.attribute_options_attributes?.[optionIndex]?.option)}
                                            </CustomFormControl>
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={1}>
                                        {this.handleRemoveAttribute(index, optionIndex, setFieldValue, remove, attribute, values?.attributes)}
                                      </Grid>
                                    </>
                                  );
                                })}
                            </Grid>

                            {attribute?.attribute_options_attributes?.length < 10 && (
                              <Grid container justifyContent="flex-end" alignItems="center">
                                <AddNewProductButton
                                  onClick={() => {
                                    const newOptions = [...(values.attributes?.[index]?.attribute_options_attributes), {option: ""}
                                  ];
                                    setFieldValue(`attributes[${index}].attribute_options_attributes`, newOptions);
                                  }}
                                  className={classes.addOption}
                                  data-test-id="addOptionButtonRef"
                                >
                                  <AddIcon style={{ color: "white", marginRight: 10, marginLeft: 0 }} />
                                  Add Option
                                </AddNewProductButton>
                              </Grid>
                            )}
                          </>
                        );
                      })}
                      <Grid container justifyContent="center" alignItems="center">
                        <AddNewProductButton data-test-id="addMoreAttributes" className={classes.addOption} onClick={() => push({ attribute_name: "", attribute_options_attributes: [""] })}>
                          <AddIcon style={{ color: "white", marginRight: 10, marginLeft: 0 }} />
                          Add more Attributes
                        </AddNewProductButton>
                      </Grid>
                    </>
                  )}
                </FieldArray>
                <Grid container justifyContent="flex-end" alignItems="center" spacing={2} style={{ paddingBottom: 15 }}>
                  <FitContentOrangeButton data-test-id="submitAttributeBtnRef" type="submit" className={classes.saveAttributes}>
                    Save Attributes
                  </FitContentOrangeButton>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    );
  };

  listExistingVariants = () => {
    const { classes } = this.props;
    return (
      <Box className={classes.listContainer} style={{ justifyContent: "flex-start", gap: "13px" }}>
        <Box className="select-title" style={{ fontWeight: 600 }}>
          Variant list
        </Box>

        <TableContainer className={classes.tableContainer}>
          <Table aria-label="simple" stickyHeader>
            <TableHead className={classes.tableBorder}>
              <TableRow style={{ fontWeight: 600 }}>
                <TableCell className={classes.tableHeaderText} style={{ whiteSpace: "nowrap" }} align="center">
                  Sr No.
                </TableCell>
                <TableCell className={classes.tableHeaderText} style={{ whiteSpace: "nowrap" }}>
                  Child SKU
                </TableCell>
                <TableCell className={classes.tableHeaderText} style={{ whiteSpace: "nowrap" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.existingVariantList.length > 0 ? (
                this.state.existingVariantList?.map((item: IExistingVariantList, index: number) => {
                  return (
                    <TableRow data-test-id="list" key={item.id}>
                      <TableCell className={classes.tableRowClass} align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell className={classes.tableRowClass}>{item?.attributes?.product_sku}</TableCell>

                      <TableCell className={classes.tableRowClass} align="center">
                        <Box style={{ display: "flex", gap: "7px", justifyContent: "center" }}>
                          <IconButton aria-label="more" aria-controls="long-menu" onClick={() => this.handleEditVariant(item)} data-test-id="EditVariantRef">
                            <img src={editIcon} />
                          </IconButton>
                          <IconButton aria-label="more" aria-controls="long-menu" onClick={() => this.handleOpenDeleteVariantModal(item.attributes.id)} data-test-id="deleteVariantRef">
                            <img src={deleteIcon} alt="delIcon" />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableCell colSpan={8}>
                  <Typography variant="h6" className={classes.text}>
                    {"No Data Available"}
                  </Typography>
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  listExistingGroups = () => {
    const { classes } = this.props;
    return (
      <Box className={classes.listContainer} style={{ justifyContent: "flex-start", gap: "13px" }}>
        <Box className="select-title" style={{ fontWeight: 600 }}>
          Group list
        </Box>
        <TableContainer className={classes.tableContainer}>
          <Table aria-label="simple" stickyHeader>
            <TableHead className={classes.tableBorder}>
              <TableRow style={{ fontWeight: 600, whiteSpace: "nowrap" }}>
                <TableCell className={classes.tableHeaderText} style={{ whiteSpace: "nowrap" }} align="center">
                  Sr No.
                </TableCell>
                <TableCell className={classes.tableHeaderText} style={{}} align="center">
                  Select group
                </TableCell>
                <TableCell className={classes.tableHeaderText} style={{}}>
                  Group name
                </TableCell>
                <TableCell className={classes.tableHeaderText} style={{}} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.existingGroupList.length > 0 ? (
                this.state.existingGroupList.map((item: IExistingGroupList, index: number) => {
                  return (
                    <TableRow data-test-id="list" key={item.id}>
                      <TableCell className={classes.tableRowClass} align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell className={classes.tableRowClass} align="center">
                        <RadioCheckbox data-test-id="radioBtnListRef" name="product" checked={item.id === this.state.selectedExistingGroupData?.id} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleSelectGroup(item, event)} />
                      </TableCell>
                      <TableCell className={classes.tableRowClass}>{item?.attributes.group_name}</TableCell>
                      <TableCell className={classes.tableRowClass} align="center">
                        <Box style={{ display: "flex", gap: "7px", justifyContent: "center" }}>
                          <IconButton aria-label="more" aria-controls="long-menu" onClick={() => this.handleEditGroup(item)} data-test-id="demoEdit">
                            <img src={editIcon} />
                          </IconButton>
                          <IconButton aria-label="more" aria-controls="long-menu" onClick={() => this.handleOpenDeleteModal(item.attributes.id)} data-test-id="addMoreIconRef">
                            <img src={deleteIcon} alt="delIcon" />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableCell colSpan={8}>
                  <Typography variant="h6" className={classes.text}>
                    {"No Data Available"}
                  </Typography>
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  listingPage = () => {
    return (
      <>
        <Box style={{ display: "flex", flexDirection: "row", gap: "4%" }}>
          <Box style={{ width: "48%" }}>{this.listExistingGroups()}</Box>
          <Box style={{ width: "48%" }}>{this.listExistingVariants()}</Box>
        </Box>
        <Box className={"next-button"} style={{ textAlign: "right", paddingBottom: 10 }}>
          <FitContentOrangeButton data-test-id="nextToListBtnRef" onClick={this.handleEditGroupPage} style={{ fontFamily: "Lato, sans-serif", padding: "6px 50px", borderRadius: 4, fontSize: 18 }}>
            Next
          </FitContentOrangeButton>
        </Box>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    const { classes } = this.props;
    const { loading, activeStep } = this.state;
    return (
      <>
        <ToastContainer />
        {loading ? <Loader2 loading={loading} /> : <></>}
        {this.deletModal()}
        {this.deletModalVariant()}
        <Box className={classes.mainContainer}>
          <Box
            className={classes.veriantContainer}
          >
            <Box style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
              <FormControlLabel style={{ margin: "0px", color: "#FF6008" }} control={<Radio disableRipple data-test-id="radioBtn" name="product" style={{ marginLeft: "-8px", marginRight: "8px", scale: "1.3" }} className={classes.radioButton} checked={true} />} label={undefined} />
              <Box style={{ color: "#FF6008", fontFamily: "Lato, sans-serif", fontWeight: 600 }}>Product Group</Box>
            </Box>
            {activeStep === "groupList" && (
              <Box style={{ display: "flex", gap: "20px" }}>
                <AddNewProductButton onClick={this.handleCreateNewGroup} style={{ fontFamily: "Lato, sans-serif", fontSize: "16px", height: "fit-content" }} data-test-id="createNewGroupRef">
                  <AddIcon style={{ color: "white", marginRight: 8, marginLeft: 0 }} />
                  Create a new group
                </AddNewProductButton>
                {this.state.existingGroupList.length > 0 && (
                  <AddNewProductButton onClick={this.handleAddNewVariant} style={{ height: "fit-content", alignSelf: "flex-end", fontSize: "16px" }} data-test-id="addnewvariantButtonRef">
                    <AddIcon style={{ color: "white", marginRight: 10, marginLeft: 0 }} />
                    Add new variant
                  </AddNewProductButton>
                )}
              </Box>
            )}
          </Box>
          {this.state.errorMsg && (
            <Typography data-test-id="nextError" className={classes.nextError}>
              {this.state.errorMsg}
            </Typography>
          )}
          {this.pageTorender()}
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  ({
    delivered: {
      color: "#979797",
      scale: "1.3",
      "&$checked": {
        color: "#ff6008"
      },
      "&.Mui-checked": {
        color: "#ff6008"
      }
    },
    backButton: {
      borderRadius: 5,
      fontSize: 16,
      backgroundColor: "#444444",
      padding: "7px 30px",
      color: "#ffffff",
      fontFamily: `"Lato-Heavy", sans-serif`,
      fontWeight: 500,
      textTransform: "capitalize" as const,
      marginRight: "20px"
    },
    tableBorder: {
      "&.MuiTableHead-root": {
        border: "1px solid rgba(151,151,151,0.33)"
      }
    },
    veriantContainer : {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      flexWrap: "wrap",
      "@media (max-width: 768px)": {
          flexDirection: "column",
          aligntems: "flex-start"
        }
    },
    submitButton: {
      borderRadius: 5,
      fontSize: 16,
      padding: "7px 30px",
      fontFamily: `"Lato-Heavy", sans-serif`,
      fontWeight: 500,
      textTransform: "capitalize" as const
    },
    tableContainer: {
      overflowX: "auto" as const,
      maxWidth: "100%",
      maxHeight: "400px"
    },
    radioButton: {
      color: "#FF6008",
      "&.Mui-checked": {
        color: "#FF6008"
      }
    },
    tableRowClass: {
      "&.MuiTableCell-body": {
        border: "none",
        fontFamily: `"Lato", sans-serif`,
        fontSize: "14px",
        fontWeight: 400,
        color: "#697a8d",
        // textAlign: "center" as const,
        padding: "16px"
      }
    },
    "&.Mui-checked": {
      color: "#ff6008"
    },
    text: {
      border: "none",
      textAlign: "center" as const,
      fontFamily: `"Lato", sans-serif`,
      padding: "10px"
    },
    tableHeaderText: {
      fontWeight: 600,
      background: "#f2f3f4",
      fontFamily: `"Lato-Heavy", sans-serif`,
      fontSize: "14px",
      color: "#33333a"
    },
    tableHeaderTextOne: {
      fontWeight: 600,
      background: "#f2f3f4",
      fontFamily: `"Lato-Heavy", sans-serif`,
      fontSize: "14px",
      color: "#33333a",
      textAlign: "center" as const,
      width: "130px"
    },
    errorStyle: {
      fontFamily: "Lato, sans-serif",
      color: "red",
      textAlign: "left" as const,
      fontSize: "12px",
      marginTop: "5px"
    },
    checkWrap: {
      height: "46px",
      borderRadius: "3px",
      width: "100%",
      justifyContent: "flex-start",
      background: "#d9f8c5",
      color: "#44a213",
      "& .MuiChip-label": {
        fontFamily: "Lato, sans-serif"
      }
    },
    mainContainer: {
      padding: 30 as const,
      display: "flex",
      gap: 20 as const,
      position: "relative",
      flexDirection: "column" as const,
      height: "calc(50vh + 14px)" as const
      // justifyContent: "space-between" as const,
    },
    selectGroupWrraper: {
      "& .select-title": {
        fontFamily: "Lato, sans-serif",
        margin: "10px 0",
        fontSize: "16px",
        fontWeight: 500
      },
      "& .list-container": {
        display: "flex",
        flexDirection: "row",
        gap: "30px"
      },
      "& .attribute-item-container": {
        display: "flex",
        flexDirection: "row",
        gap: "12px",
        flexWrap: "wrap"
      },
      "& .checkbox-selected ": {
        "& .MuiFormControlLabel-label": {
          fontFamily: "Lato, sans-serif",
          marginTop: "10px",
          fontSize: "16px",
          fontWeight: 500,
          width: "fit-content",
          paddingBottom: "4px"
        },
        "& .list-container": {
          display: "flex",
          flexDirection: "row",
          gap: "15px"
        },
        "& .attribute-item-container": {
          display: "flex",
          flexDirection: "row"
        },
        "& .checkbox-selected ": {
          "& .MuiFormControlLabel-label": {
            fontFamily: "Lato, sans-serif",
            fontSize: "18px",
            fontWeight: 600,
            color: "#ff6008"
          }
        },
        "& .checkbox-unselected ": {
          "& .MuiFormControlLabel-label": {
            fontFamily: "Lato, sans-serif",
            fontSize: "18px",
            fontWeight: 600,
            color: "#747474"
          }
        }
      },
      "& .checkbox-unselected ": {
        "& .MuiFormControlLabel-label": {
          fontFamily: "Lato, sans-serif",
          fontSize: "18px",
          fontWeight: 600,
          color: "#747474"
        }
      }
    },
    confirmCancelButton: {
      display: "flex" as const,
      flexDirection: "row",
      justifyContent: "space-between",
      // position: "absolute",
      paddingBottom: "20px",
      bottom: 10,
      width: "100%",
      right: 10
    },

    deleteWrap: {
      alignItems: "center",
      display: "flex",
      paddingLeft: "20px"
    },
    addAttributeFormikWrraper: {
      display: "flex",
      flexDirection: "column",
      padding: "0 0 20px 0",
      justifyContent: "space-between",
      height: "100%",
      margin: 0,
      "& .select-title": {
        fontFamily: "Lato, sans-serif",
        marginTop: "10px",
        fontSize: "16px",
        fontWeight: 500,
        borderBottom: "1px solid #d0d0d0",
        width: "fit-content",
        paddingBottom: "4px"
      },
      "& .list-container": {
        display: "flex",
        flexDirection: "row",
        gap: "15px"
      },
      "& .attribute-item-container": {
        display: "flex",
        flexDirection: "row"
      },
      "& .checkbox-selected ": {
        "& .MuiFormControlLabel-label": {
          fontFamily: "Lato, sans-serif",
          fontSize: "18px",
          fontWeight: 600,
          color: "#ff6008"
        }
      },
      "& .checkbox-unselected ": {
        "& .MuiFormControlLabel-label": {
          fontFamily: "Lato, sans-serif",
          fontSize: "18px",
          fontWeight: 600,
          color: "#747474"
        }
      }
    },
    addVariantFormikWrraper: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      justifyContent: "space-between",
      height: "100%",
      padding: "0 0 20px 0",
      margin: 0,
      "& .selected-attribute-wrraper": {
        display: "flex",
        flexDirection: "column",
        gap: "35px"
      },
      "& .select-title": {
        fontFamily: "Lato, sans-serif",
        margin: "10px 0",
        fontSize: "16px",
        fontWeight: 500
      },
      labelHeader: {
        margin: "6px 5px 8px 0",
        width: "100%",
        color: "#8a8a8c",
        fontWeight: 600,
        fontSize: "13px",
        fontFamily: "Lato, sans-serif",
        lineHeight: "14.4px",
        letterSpacing: "0.3",
        ".MuiInputBase-root": {
          width: "100% !important"
        }
      },
      "& .next-button": {
        display: "flex" as const,
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "100%",
        gap: 12,
        padding: "12px 0",
        button: {
          fontFamily: "Lato, sans-serif"
        }
      }
    },
    listContainer: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      height: "100%",
      "& .select-title": {
        fontFamily: "Lato, sans-serif",
        margin: "10px 0",
        fontSize: "16px",
        fontWeight: 500
      },
      "& .next-button": {
        display: "flex" as const,
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "100%",
        padding: "12px 0"
      }
    },
    nextError: {
      color: "red",
      textAlign: "end" as const,
      fontSize: "12px",
      marginRight: "20px",
      display: "flex",
      alignItems: "center"
    },
    validateWrap: {
      display: "flex",
      alignItems: "center"
    },
    check: {
      color: "#44a213"
    },
    sku: {
      color: "#666666",
      fontFamily: `"Lato-Semibold",sans-serif`,
      fontSize: "12px",
      fontWeight: 400
    },
    validateBtn: {
      padding: "8px",
      minWidth: 124,
      borderRadius: "4px",
      marginTop: 31,
      "&.Mui-disabled": {
        opacity: 0.8,
        color: "#fff"
      }
    },
    labelHeader: {
      margin: "6px 5px 10px 0",
      width: "100%",
      color: "#9a9a9a",
      fontWeight: 400,
      fontSize: "12px",
      fontFamily: "Lato, sans-serif",
      lineHeight: "14.4px",
      ".MuiInputBase-root": {
        width: "100% !important"
      }
    },
    addOption: {
      fontSize: "16px",
      "@media (max-width: 850px)" : {
        marginTop: '20px'
      }
    },
    saveAttributes: { 
      padding: 10, 
      borderRadius: 4, 
      fontSize: 14,
      "@media (max-width: 850px)" : {
        marginTop: '20px'
      }
    },
    attributeName: {
      paddingRight: '20px'
    },
    cancelWrap: {
      height: "46px",
      borderRadius: "3px",
      width: "100%",
      justifyContent: "flex-start",
      background: "rgb(255,229,231)",
      color: "rgb(255,85,111)",
      "& .MuiChip-label": {
        fontFamily: "Lato, sans-serif"
      }
    },
    cancel: {
      color: "rgb(255,85,111)"
    }
  } as const);

export default withStyles(styles)(Variant);
export { Variant };
// Customizable Area End
