import React from "react";

// Customizable Area Start
import { theme } from "../../dashboard/src/CustomizedElementStyle";
import { ThemeProvider } from "@material-ui/core/styles";
import { Box, Container, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import CustomPagination from "./CustomElementStyling";
// Customizable Area End

import FeaturesController, { Props } from "./FeaturesController";
import DOMPurify from "dompurify";

class Pricing extends FeaturesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <ThemeProvider theme={theme}>
          <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Container style={{ position: "relative", textAlign: "start", maxWidth: "96vw" }}>
              <Grid container style={{ marginTop: 160, marginBottom:'60px' }} spacing={3}>
                <Grid item lg={12} sm={12} xs={12}>
                {this.props.pricingContent &&
                  <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.pricingContent.content)}} data-test-id="pricingContent">
                  </div>
                }
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Divider />
          {this.props.footer()}
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default Pricing;
const webStyle = {
  selected: {
    backgroundColor: "transparent",
    color: "orange"
  },
  res: {},
  credits: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    height: "100%",
    fontWeight: 500,
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.7)",
    width: "100%"
  },
  mainWrapper: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    overflowX: "hidden"
  },
  navigation: {
    paddingRight: "50px",
    border: "none",
    height: "50px",
    width: "fit-content",
    alignItems: "center",
    display: "flex",
    fontWeight: 400
  },
  privacyText:{
    fontFamily: "Lato, sans-serif",
    fontSize: '14px',
    color: 'rgb(87,86,86)',
    cursor: 'pointer'
  },
  signUpForFree: {
    width: "fit-content",
    fontSize: 16,
    fontWeight: 700,
    color: "white",
    borderRadius: "7px",
    fontFamily: `"Lato", sans-serif`
  },
  text: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: 16,
    color: "#A8A8A8"
  },
  container: { 
    fontSize: 24, 
    fontFamily: `"Lato",  sans-serif`, 
    fontWeight: 600, 
    color: "#F3732B",
    "@media (max-width: 768px)": {
      "margin-top": "100px",
    }
  }
};
// Customizable Area End
