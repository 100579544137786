import React from "react";

// Customizable Area Start
import { Box, Grid, Button, Typography, Theme, withStyles, Hidden } from "@material-ui/core";
import { logoImg, objectsImg } from "./EmailAccountRegistrationController";
import FeaturesController, { Props, configJSON } from "./FeaturesController";
import Features from "./Features.web";
import Whyus from "./Whyus.web";
import Pricing from "./Pricing.web";
import SellerAccountRegistration from "./SellerAccountRegistration.web";
// Customizable Area End

class FeaturesHeader extends FeaturesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderByEzzyRights = () => {
    return(
      <Grid style={webStyle.noBorder} item lg={"auto"} md={12} xs={12} sm={12} >
        <Box sx={webStyle.credits}>@ 2024 ByEzzy.com All rights reserved</Box>
      </Grid>
    )
  }

  footer = () => {
    return (
      <Grid container style={webStyle.footerContainer}>
        <Grid item lg={12} sm={12} xs={12} style={webStyle.footerGrid}>
          <Box sx={webStyle.footerBox}>
            <Grid justifyContent="space-between" spacing={0} container >
              <Grid item>
                <Grid spacing={0} container>
                  <Grid item style={webStyle.noBorder}>
                    <Box
                      sx={webStyle.sellText}>
                      Sell with ByEzzy
                    </Box>
                    <Grid container spacing={0}>
                      <Grid item>
                        <Box
                          sx={{
                            marginBottom: "20px",
                            fontSize: 14,
                            color: "white",
                            marginRight: "40px",
                          }}>
                          <Grid  spacing={1} container direction="column">
                            <Typography style={webStyle.textOne} data-test-id="cost" onClick={() => this.setFooterTitle("Cost & Commission details")}>Cost & Commission details</Typography>
                            <Typography style={webStyle.textOne} data-test-id="guide" onClick={() => this.setFooterTitle("Beginner's guide")}>Beginner's guide</Typography>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          sx={{
                            marginBottom: "20px",
                            marginRight: "40px",
                            fontSize: 14,
                            color: "white",
                          }}>
                          <Grid container spacing={1} direction="column">
                          <Typography style={webStyle.textOne} data-test-id="fulfillment" onClick={() => this.setFooterTitle("Fulfillment by ByEzzy")}>Fulfillment by ByEzzy</Typography>
                          <Typography style={webStyle.textOne} data-test-id="advertise" onClick={() => this.setFooterTitle("Advertise on ByEzzy")}>Advertise on ByEzzy</Typography>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          sx={{
                            fontSize: 14,
                            marginRight: "40px",
                            color: "white",
                            marginBottom: "20px",
                          }}>
                          <Grid container spacing={1} direction="column">
                          <Typography style={webStyle.textOne}  data-test-id="brand-store" onClick={() => this.setFooterTitle("Brand store")}>Brand store</Typography>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ marginBottom: "20px", border: "none" }}>
                    <Box
                      sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        marginBottom: "20px",
                      }}>
                      ByEzzy Legal
                    </Box>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Box sx={{ color: "white", fontSize: 14, marginRight: "40px", marginLeft: '5px' }}>
                          <Grid container spacing={1} direction="column">
                            <Typography data-test-id="privacy-one" style={webStyle.textOne} onClick={this.handlePrivacy}>
                              Privacy notice
                            </Typography>
                            <Typography data-test-id="terms-one" style={webStyle.textOne} onClick={this.handleTerms}>
                              Terms of service
                            </Typography>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {this.renderByEzzyRights()}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
  };

  background = () => {
    return (
      <Hidden smDown>
        <Box sx={webStyle.imgBoxOne}>
          <img src={objectsImg} width={"90%"} />
        </Box>
      </Hidden>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  render() {
    const { currentTab } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Typography ref={this.registerRef} id="landing">
        <Grid container spacing={0} style={webStyle.header}>
          <Grid item lg={8} md={8} className={classes?.navContent}>
            <Grid item>
              <img data-test-id="logo" src={logoImg} style={webStyle.logo} onClick={() => this.handleLogo()} />
            </Grid>
            <Grid item lg={6} sm="auto" xs="auto" style={webStyle.nav}>
              <Grid item xs={3}>
                <Box sx={webStyle.navigation} style={{ cursor: "pointer", borderBottom: currentTab === "features" ? "4px solid #F3732B" : "none" }} data-test-id="featuresId" onClick={() => this.handleFeatures("features")}>
                  {configJSON.featuresText}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{...webStyle.navigation, whiteSpace: 'nowrap'}} style={{ borderBottom: currentTab === "whyus" ? "4px solid #F3732B" : "none", cursor: "pointer" }} data-test-id="whyusId" onClick={() => this.handleWhyus("whyus")}>
                  {configJSON.whyusText}
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={webStyle.navigation} style={{ borderBottom: currentTab === "pricing" ? "4px solid #F3732B" : "none", cursor: "pointer" }} data-test-id="pricingId" onClick={() => this.handlePricing("pricing")}>
                  {configJSON.pricingText}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {this.state.currentTab !== "register" ? (
            <Grid item lg={4} style={webStyle.signupBox}>
              <Box className={classes?.signUpButton}>
                <Button data-test-id="sign" style={webStyle.signUpForFree} onClick={() => this.handleRegistration("register")}>
                  {configJSON.signupForFreeText}
                </Button>
              </Box>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        </Typography>
        {this.state.currentTab === "register" && <SellerAccountRegistration navigation={this.props.navigation} id={"register"} ></SellerAccountRegistration>}
        {this.state.currentTab === "features" && <Features navigation={this.props.navigation} id={"features"} footer={this.footer} background={this.background} handleBecomeAPartner={this.handleBecomeAPartner} featuresContent={this.state.featuresContent}></Features>}
        {this.state.currentTab === "whyus" && <Whyus navigation={this.props.navigation} id={"whyus"} footer={this.footer} background={this.background} whyUsContent={this.state.whyUsContent} ></Whyus>}
        {this.state.currentTab === "pricing" && <Pricing navigation={this.props.navigation} id={"pricing"} footer={this.footer} background={this.background} pricingContent={this.state.pricingContent}></Pricing>}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export { FeaturesHeader };
const webStyle = {
  header: {
    top: 30,
    left: 0,
    width: "100%",
    padding: "0rem 50px 0rem 50px",
    color: "#575656",
    fontFamily: `"Lato",  sans-serif`,
    position: "absolute" as const, 
    zIndex: 1 
  },
  credits: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontWeight: 500,
    fontSize: 14,
    color: "white"
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    width: "100%",
    overflowX: "hidden"
  },
  navigation: {
    paddingBottom: 3,
    border: "none",
    margin: 20,
    width: "fit-content",
    alignItems: "center",
    display: "flex",
    fontWeight: 400
  },
  signUpForFree: {
    padding: "10px 22px",
    borderRadius: "6px",
    background: "rgba(253, 97, 12, 1)",
    fontSize: 16,
    fontWeight: 600,
    color: "white",
    fontFamily: `"Lato",  sans-serif`,
    height: "max-content",
    width: "max-content", 
    textTransform: "none" as const,
    cursor: "pointer" 
  },
  text: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: 16,
    color: "#A8A8A8"
  },
  container: { 
    border: "none", 
    display: "flex", 
    flexDirection: "row" as const
  },
  logo: {
    cursor: "unset", 
    width:'102px', 
    height:'85px',
    marginTop:'-10px'
  },
  nav: { 
    display: "flex", 
    flexDirection: "row" as const, 
    justifyContent: "space-evenly" 
  },
  signupBox: { 
    display: "flex", 
    justifyContent: "end", 
    border: "none"
  },
  imgBoxOne: { 
    position: "absolute" as const, 
    top: 176, 
    left: 25, 
    zIndex: 0 
  },
  textOne: {
    fontFamily: "Lato, sans-serif",
    fontSize: '14px',
    color: 'white',
    cursor: 'pointer'
  },
  footerContainer: {
    padding: '0  40px', 
    background: 'rgb(243, 115, 43)', 
    color: 'white', 
    fontFamily: 'Lato, sans-serif'
  },
  footerGrid: { 
    padding: "49px 0px" 
  },
  footerBox: { 
    border: "none", 
    width: "100%" 
  },
  noBorder: { 
    border: "none" 
  },
  sellText: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: "20px",
  }
};
const styles = (theme: Theme) => (
  {
    navContent: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      border: "none", 
      flexDirection: "row" as const,
      "@media (max-width: 768px)": {
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        zIndex: 100
      }
    },
    signUpButton: {
      "@media (max-width: 768px)": {
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginLeft: "55px"
      } 
    }
  }
)
export default withStyles(styles)(FeaturesHeader);
// Customizable Area End
