import React from "react";

// Customizable Area Start
import { theme } from "../../dashboard/src/CustomizedElementStyle";
import { ThemeProvider } from "@material-ui/core/styles";
import { Box, Container, Grid, Divider } from "@material-ui/core";
// Customizable Area End

import FeaturesController, { Props } from "./FeaturesController";
import DOMPurify from "dompurify";

class Whyus extends FeaturesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <ThemeProvider theme={theme}>
          <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {this.props.background()}
            <Container style={{ position: "relative", textAlign: "start", maxWidth: "96vw" }}>
              <Grid container style={{ marginTop: 160, marginBottom:'60px' }}>
                <Grid item lg={12} sm={12} xs={12}>
                {this.props.whyUsContent &&
                  <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.whyUsContent.content)}} data-test-id="whyusContent">
                  </div>
                }
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Divider />
          {this.props.footer()}
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default Whyus;
const webStyle = {
  res: {},
  credits: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontWeight: 500,
    width: "100%",
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.7)"
  },
  mainWrapper: {
    display: "flex",
    alignItems: "center",
    background: "#fff",
    width: "100%",
    overflowX: "hidden",
    flexDirection: "column"
  },
  navigation: {
    paddingRight: "50px",
    border: "none",
    width: "fit-content",
    height: "50px",
    alignItems: "center",
    display: "flex",
    fontWeight: 400
  },
  signUpForFree: {
    width: "fit-content",
    color: "white",
    fontSize: 16,
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`,
    borderRadius: "7px"
  },
  text: {
    fontSize: 16,
    fontFamily: `"Lato", sans-serif`,
    color: "#A8A8A8"
  },
  textOne: {
    fontFamily: "Lato, sans-serif",
    fontSize: '14px',
    color: 'rgb(87,86,86)',
    cursor: 'pointer'
  },
  container: { 
    fontFamily: `"Lato",  sans-serif`, 
    fontSize: 24, 
    fontWeight: 600, 
    color: "#F3732B",
    "@media (max-width: 768px)": {
      "margin-top": "100px",
    }
  }
};
// Customizable Area End
